import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import instance from "../BaseUrl/BaseUrl";
import Tree from "react-d3-tree";
import clipboardCopy from "clipboard-copy";
// import { ToastContainer, toast } from "react-toastify";
import useEncryption from "../EncryptData/EncryptData";
import { toast } from "react-hot-toast";

// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const ViewOneUser = () => {
  const nodeRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getPath = localStorage.getItem("metamask");

  const { decryptData } = useEncryption();

  let dataList = [];
  // console.log("/🚀 ~ ViewOneUser ~ dataList:", dataList)

  //tree

  const [orgChart, setOrgChart] = useState({
    name: "Root",
    children: [],
  });
  // console.log("🚀 ~ orgChart==================", orgChart);

  const handleNodeClick = (node) => {
    clipboardCopy(node.name);

    toast.success("wallet address copied");
  };

  //pagination
  const [hoveredNode, setHoveredNode] = useState(null);

  //format date from params
  const temp = [];
  // console.log("🚀 ~ ViewOneUser ~ temp:", temp);

  const id = localStorage.getItem("userId");
  // console.log("🚀 ~ ViewOneUser ~ id:", id);

  useEffect(() => {
    // console.log("🚀 ~ ViewOneUser ~ orgChart:", orgChart);
    const getUsers = async () => {
      try {
        const res = await instance.get(`/refaralDetails/${id}`);
        const responseData = decryptData(res?.data?.data);
        // console.log("🚀 ~ useEffect ~ res:===============", responseData);
        let data = {};

        try {
          data = JSON.parse(responseData?.data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
        // console.log("🚀 ~  ~ data:===============", data);

        // root node
        const orgChart = {
          name: getPath,
          children: [],
        };
        dataList.push(getPath);

        if (data.referrals && data.referrals.length > 0) {
          data.referrals.slice(0, 5).forEach((item) => {
            //  node 1
            const childNode = {
              name: item?.user?.walletAddress,
              children: [],
              id: item.user?._id,
            };

            if (item.referrals && item.referrals.length > 0) {
              item.referrals.slice(0, 5).forEach((referralItem) => {
                //  node 2
                const grandChildNode = {
                  name: referralItem.user?.walletAddress,
                  children: [],
                };

                if (
                  referralItem.referrals &&
                  referralItem.referrals.length > 0
                ) {
                  referralItem.referrals
                    .slice(0, 5)
                    .forEach((innerReferralItem) => {
                      //  node 3
                      const level3ChildNode = {
                        name: innerReferralItem.user?.walletAddress,
                        children: [],
                      };

                      if (
                        innerReferralItem.referrals &&
                        innerReferralItem.referrals.length > 0
                      ) {
                        innerReferralItem.referrals
                          .slice(0, 5)
                          .forEach((level4Item) => {
                            //  node 4
                            const level4ChildNode = {
                              name: level4Item.user?.walletAddress,
                              children: [],
                            };

                            if (
                              level4Item.referrals &&
                              level4Item.referrals.length > 0
                            ) {
                              level4Item.referrals
                                .slice(0, 5)
                                .forEach((level5Item) => {
                                  //  node 5
                                  const level5ChildNode = {
                                    name: level5Item.user?.walletAddress,
                                    children: [],
                                  };

                                  // Add node 5 to node 4
                                  level4ChildNode.children.push(
                                    level5ChildNode
                                  );
                                });
                            }

                            // Add node 4 to node 3
                            level3ChildNode.children.push(level4ChildNode);
                          });
                      }

                      // Add node 3 to node 2
                      grandChildNode.children.push(level3ChildNode);
                    });
                }

                // Add node 2 to node 1
                childNode.children.push(grandChildNode);
              });
            }

            // Add node 1 to orgChart
            orgChart.children.push(childNode);
          });
        }

        // const temp1 = dataList
        //   .slice(startIndex, endIndex)
        //   .map((item, index) => ({
        //     id: startIndex + index + 1,
        //     walletAddress: item,
        //     CopyWalletAddress: (
        //       <button onClick={() => handleNodeClick(item)}>
        //         <IoCopyOutline />
        //       </button>
        //     ),
        //   }));
        // setPage(Math.ceil(dataList.length / entriesPerPage));

        setOrgChart(orgChart);
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }
    };
    getUsers();
  }, []);

  return (
    <>
      <div style={{ width: "100%", height: "500px" }}>
        <Tree
          data={orgChart}
          translate={{ x: 500, y: 50 }}
          orientation="radial"
          // pathFunc="diagonal"
          // nodeSize={{ x: 50, y: 150 }}
          allowForeignObjects
          // transitionDuration={100}

          renderCustomNodeElement={({ nodeDatum }) => (
            <g
              className="rd3t-node"
              onClick={() => handleNodeClick(nodeDatum)}
              onMouseEnter={() => setHoveredNode(nodeDatum)}
              onMouseLeave={() => setHoveredNode(null)}
            >
              <circle r={15} fill="lightblue" style={{ cursor: "pointer" }} />
              <foreignObject width={500} height={50}>
                <div
                  ref={nodeRef}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    visibility:
                      hoveredNode === nodeDatum ? "visible" : "hidden",

                  }}
                >
                  {nodeDatum.name}
                </div>
              </foreignObject>
            </g>
          )}
        />
      </div>
    </>
  );
};
export default ViewOneUser;
