import React from "react";
import "../Login/SignUp.css";
import AirTickets from "./AirTickets/AirTickets";
import Dashboard from "./Dashboard/Dashboard";
import NodeTypes from "./nodeTypes/NodeTypes";
import Types from "./Types/Types";
// import SmartGIF from "../../img/smart video NB_1.gif";
import SmartGIF from "../img/smart video NB_1.gif";
import PowerGIF from "../img/Power video NB_1.gif";
import MasterGIF from "../img/Master video NB_1.gif";
import EClass from "../assets/airTicketVideo/02.gif";
import BClass from "../assets/airTicketVideo/03.gif";
import FClass from "../assets/airTicketVideo/01.gif";

function dashboard() {
  return (
    <>
      <Dashboard />

      <AirTickets />
      {/* <NodeTypes /> */}

      <Types
        title="Air Ticket Types"
        subtitle1="E Class"
        subtitle2="B Class"
        subtitle3="F Class"
        img1={EClass}
        img2={BClass}
        img3={FClass}
      />

      <Types
        title="Node Types"
        subtitle1="Smart Node"
        subtitle2="Power Node"
        subtitle3="Master Node"
        img1={SmartGIF}
        img2={PowerGIF}
        img3={MasterGIF}
      />
    </>
  );
}

export default dashboard;
