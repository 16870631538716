import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import instance from "../../BaseUrl/BaseUrl";
import useEncryption from "../../EncryptData/EncryptData";
import home from "../..//img/home.png";
import thounder from "../..//img/mythunder.png";
import king from "../..//img/myking.png";
import eclass from "../../img/classes/eclass.png"
import bclass from "../../img/classes/bclass.png"
import fclass from "../../img/classes/fclass.png"
import MainTitle from "../../MainTitle/MainTitle";

const AirTicketMain = ({ totlenode }) => {
  const { decryptData } = useEncryption();
  const [tickets, setTickets] = useState({});

  const [rewards, setRewards] = useState();

  const getAirTickets = async () => {
    try {
      const result = await instance.get("/getAirTickets");

      const results = decryptData(result.data.data);

      if (results.status) {
        setTickets(results);
      } else {
        // toast.error(results.message);
      }
    } catch (err) {}
  };

  const getRewards = async () => {
    try {
      const result = await instance.get("/rewards");

      const results = decryptData(result.data.data);

      if (results.status) {
        setRewards(results.rewards);
      } else {
        // toast.error(results.message);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getAirTickets();
    getRewards();
  }, []);

  const claimTickets = [
    {
      id: 0,
      card: totlenode === undefined ? 0 : totlenode,
      img: eclass,
      ticketClass: tickets?.eclass,
    },
    {
      id: 1,
      card: 0,
      img: bclass,
      ticketClass: tickets?.bclass,
    },
    {
      id: 2,
      card: 0,
      img: fclass,
      ticketClass: tickets?.fclass,
    },
  ];

  return (
    <>
      <div className="flex lg:flex-row flex-col justify-between items-center py-6 mt-3 bg-[#DFE5FF] rounded-xl px-10  nodetype-bg">
        <div className="">
          <p className="md:text-[40px] text-2xl text-[#7351FC] font-extrabold">
            Total Tickets
          </p>
          <p className="lg:text-[91px] text-[40px] text-color text-center lg:text-start">
            {Object.keys(tickets).length === 0
              ? 0
              : tickets?.bclass + tickets?.eclass + tickets?.fclass}
          </p>
          <p className="text-color border-t-2 py-4 border-white"></p>
        </div>
        <div>
          <div className="flex justify-center items-center gap-2 md:gap-5 mt-2 ">
            {claimTickets?.map((index, key) => (
              <>
                <div
                  className=" Rewards rounded-lg gap-5 flex justify-center items-center flex-col"
                  key={index.id}
                >
                  <div className="flex flex-col p-5 justify-between w-16 md:w-auto">
                    <img
                      src={index.img}
                      alt=""
                      className="md:w-14 md:h-14 w-5 h-5"
                    />
                    <p className="rewardstextcolor mt-5 text-xl">
                      {Object.keys(tickets).length === 0
                        ? 0
                        : index.ticketClass}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-4">
        <MainTitle title={"Total Rewards"} />
      </div>
      <div className="flex lg:flex-row flex-col justify-between items-center py-6 mt-3 bg-[#DFE5FF] rounded-xl px-10  nodetype-bg">
        <div>
          <p className="md:text-[40px] text-2xl text-[#7351FC] font-extrabold">
            Total Rewards
          </p>
          <p className="lg:text-[91px] text-[40px] text-color text-center lg:text-start">
            {rewards}
          </p>
          <p className="text-color border-t-2 py-4 border-white"></p>
        </div>
        <div>
          {/*
          <div className="flex justify-center items-center  gap-5 mt-2 ">
            {claimTickets?.map((index, key) => (
              <>
                <div
                  className=" Rewards rounded-lg gap-5 flex justify-center items-center flex-col"
                  key={index.id}
                >
                  <div className="flex flex-col p-5 justify-between ">
                    <img
                      src={index.img}
                      alt=""
                      className="md:w-14 md:h-14 w-5 h-5"
                    />
                    <p className="rewardstextcolor mt-5 text-xl">
                      {Object.keys(tickets).length === 0
                        ? 0
                        : index.ticketClass}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
          */}
        </div>
      </div>
    </>
  );
};

export default AirTicketMain;
