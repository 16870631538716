import React from "react";
import MainTitle from "../../MainTitle/MainTitle";
import "../../Login/SignUp.css";
import home from "../..//img/home.png";
import thounder from "../..//img/mythunder.png";
import king from "../..//img/myking.png";
import useEncryption from "../../EncryptData/EncryptData";
import AirTicketMain from "../../Dashboard/AirTickets/AirTicketMain";
import { ethers } from "ethers";
import { useEffect } from "react";
import HocNodes from "../../HOC/HocNodes";

function MyNodedetalis(props) {
  const { totlenode, nodeDetails } = props;
  const { smartnode, powernode, masternode } = nodeDetails;

  const provider = new ethers.providers.JsonRpcProvider(
    `https://sepolia.infura.io/v3/0bb35d05064b42fb96254e5f7eb2c2b2`
    // "https://bscrpc.com"
  );

  const blockchainData = async () => {
    const balance = await provider.getBalance(
      "0xfc494cD05d4C2C8f8e01587161FBd167269B85dd"
    );
    // console.log("🚀 ~ blockchainData ~ balance:", balance);

    const balanceEther = ethers.utils.formatEther(balance);
    // console.log("🚀 ~ blockchainData ~ balanceEther:", balanceEther);
  };

  useEffect(() => {
    blockchainData();
  }, []);

  const { decryptData } = useEncryption();
  const getDetelis = decryptData(localStorage.getItem("quantity"));
  // =======claim data========
  const claim = [
    {
      id: 0,
      card: smartnode || 0,
      img: home,
    },
    {
      id: 1,
      card: powernode || 0,
      img: thounder,
    },
    {
      id: 2,
      card: masternode || 0,
      img: king,
    },
  ];

  return (
    <>
      <div className="container  mx-auto p-10">
        <div className="mt-7 flex-col md:flex-row ">
          <MainTitle title={"My Node"} />
        </div>
        <div className="flex lg:flex-row flex-col justify-between items-center py-6 mt-3 bg-[#DFE5FF] rounded-xl px-10  nodetype-bg">
          <div className="">
            <p className="md:text-[40px] text-2xl text-[#7351FC] font-extrabold">
              My Nodes
            </p>
            <p className="lg:text-[91px] text-[40px] text-color text-center lg:text-start">
              {totlenode || 0}
            </p>
            <p className="text-color border-t-2 py-4 border-white">
              {/* Total Average Tax 0% */}
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center gap-2 md:gap-5 mt-2 ">
              {claim?.map((index, key) => (
                <>
                  <div
                    className=" Rewards rounded-lg gap-5 flex justify-center items-center flex-col"
                    key={index.id}
                  >
                    <div className="flex flex-col p-5 justify-between w-16 md:w-auto">
                      <img
                        src={index.img}
                        alt=""
                        className="md:w-14 md:h-14 w-5 h-5"
                      />
                      <p className="rewardstextcolor mt-5 text-xl">
                        {index.card}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-7">
          <MainTitle title={"My NFT Air Tickets"} />
          <AirTicketMain totlenode={totlenode} />
        </div>
      </div>
    </>
  );
}

export default HocNodes(MyNodedetalis);
