import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "../../Button/Button";
import instance from "../../BaseUrl/BaseUrl";
import useEncryption from "../../EncryptData/EncryptData";
import { toast } from "react-hot-toast";
import { ethers } from "ethers";
import { wizzABI, wizzPrivateKey ,withdrawABI} from "../../../ABI";
import Web3 from "web3";

// const provider = new ethers.providers.JsonRpcProvider(
//   "https://bsc.publicnode.com"
// );
const provider = new ethers.providers.JsonRpcProvider(
  "https://bsc-dataseed.binance.org/"
);

const Withdraw = ({ closeModal, withdrawReward, rewardName, limit }) => {
  // console.log("🚀 ~ Withdraw ~ limit:", limit);
  const [address, setAddress] = useState("");
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [userWithdrawStatus, setuserWithdrawStatus] = useState();
  const { encryptData, decryptData } = useEncryption();
  const getDetelis = decryptData(localStorage.getItem("details"));
  const username = getDetelis?.data?.userData?.username;

  function increment() {
    setValue((prevCount) => {
      if (prevCount === "") {
        return Number("1");
      } else if (Number(prevCount) < withdrawReward / 10.0) {
        return Number((Number(prevCount) + 1).toString());
      } else {
        return prevCount;
      }
    });
  }

  function decrement() {
    setValue((prevCount) => {
      if (prevCount === "") {
        return Number("0");
      } else if (Number(prevCount) > 0) {
        return Number((Number(prevCount) - 1).toString());
      } else {
        return prevCount;
      }
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "wallet":
        const regex = /^[a-zA-Z0-9]*$/; // regular expression to match letters and numbers
        if (!regex.test(value)) {
          return; // if the value contains non-alphanumeric characters, return without updating the state
        }
        setAddress(value);
        break;

      case "withdrawAmount":
        const minValue = 1;
        const maxValue = limit ? limit : Math.floor(withdrawReward / 10);
        const newValue =
          value === ""
            ? ""
            : Math.max(
                minValue,
                Math.min(maxValue, parseFloat(value).toFixed(1))
              );
        setValue(newValue);
        break;

      default:
        break;
    }
  };

  // const {abi}  = wizzABI;
  useEffect(() => {
    const iserWithdrawStatus = localStorage.getItem("blockWithdraw");
    setuserWithdrawStatus(iserWithdrawStatus);
  }, []);
  // const transferWizzTokens = async () => {
  //   try {
  //     const wallet = new ethers.Wallet(wizzPrivateKey, provider);
  //     const tokenAddress = "0x2aD69E58071b95C047bF78dc804DB3662275b64A";
  //     const tokenContract = new ethers.Contract(tokenAddress, wizzABI, wallet);

  //     const parsedAmount = ethers.utils.parseUnits(value.toString(), 18);
  //     // console.log("/🚀 ~ transferWizzTokens ~ parsedAmount:", parsedAmount);

  //     setLoading(true);
  //     const tx = await tokenContract.transfer(address, parsedAmount);
  //     // console.log(`Transaction hash: ${tx.hash}`);

  //     const receipt = await tx.wait();
  //     // console.log(`Transaction confirmed in block ${receipt.blockNumber}`);

  //     if (receipt.blockNumber) {
  //       toast.success(`Withdrawn of ${value} tokens is Successful`);
  //     }
  //   } catch (error) {
  //     toast.error("Please try again later");
  //   } finally {
  //     setLoading(false);
  //     closeModal();
  //   }
  // };


//withdraw
  const transferWizzTokens = async () => {
    
    try {
      const contractaddress = "0x05879bDb48F58594e88dfF248355C1f7F54E5dF0";

      const walletProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      const sendContractTx = new ethers.Contract(
        contractaddress,
        withdrawABI,
        walletProvider.getSigner()
      );

      // Check if value is defined and not null
      if (value) {
        const balanceInEther = ethers.utils.parseUnits(value.toString(), 18);
        const gasLimit = 300000; // Set a manual gas limit
        const withdrawData = await sendContractTx.withdraw(balanceInEther, { gasLimit });
        if (withdrawData) {
          toast.success("withdraw successfully");
        }
      } else {
        console.log("Value is not defined or null");
      }
    } catch (error) {
      console.log(
        "🚀 ~ transferWizzTokens ~ error===================:",
        error
      );
      toast.error("Contract is not executed, Please try again.");
    } finally {
      setLoading(false);
      closeModal();
    }
 
};
  const submitHandler = async (e) => {
    e.preventDefault();
    if (userWithdrawStatus === "Unblocked") {
      try {
        const encrypt = encryptData(
          JSON.stringify({
            name: username,
            [rewardName === "air ticket" ? "ticketRewards" : "nodeRewards"]:
              value,
            address,
          })
        );

        const result = await instance.post(
          rewardName === "air ticket" ? "/withdrawTickets" : "/withdrawNodes",
          {
            data: encrypt,
          }
        );
        console.log("🚀 ~ submitHandler ~ result:", result)

        const results = decryptData(result.data.data);
        console.log("🚀 ~ submitHandler ~ results:", results);

        if (results.status) {
          transferWizzTokens();
        } else {
          toast.error(results.message);
        }
      } catch (err) {
        // console.log("🚀 ~ submitHandler ~ err:", err);
        // toast.error(err.message);
        toast.error("Please try again later");
      }
    } else {
      toast.error("contact admin to restore account");
    }
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeModal]);

  const inpClass =
    "focus:outline-none font-light w-full h-10 flex items-center bg-[#97A5FC] text-black border-y text-center placeholder:text-black caret-black";

  return (
    <>
      <div
        className="py-3 z-50 flex justify-center items-center mx-auto fixed top-0 right-0 bottom-0 left-0 backdrop-blur-md bg-black/50"
        id="modal"
      >
        <div
          className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
          ref={loading ? null : modalRef}
        >
          <div className="relative py-8 px-5 md:px-10 nodetype-bg border-[#14206A] border-2 rounded-3xl shadow-2xl -3xl text-white">
            {loading && (
              <p>
                Your transaction is under process. This will take upto 15
                seconds. Please wait...
              </p>
            )}
            {!loading && (
              <>
                <div
                  className=" cursor-pointer outline-none border-none absolute top-0 right-0 mt-4 mr-5 text-[#CFD6FE] transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                  onClick={closeModal}
                >
                  <i className="fa-sharp fa-solid fa-xmark"></i>
                </div>
                <p className="text-center mt-2">
                  Each month, a maximum of 10% of rewards can be withdrawn.
                </p>

                <form className="" onSubmit={submitHandler}>
                  <div className="my-5">
                    <input
                      type="text"
                      className={`${inpClass} rounded-md`}
                      placeholder="Wallet Address"
                      value={address}
                      name="wallet"
                      onChange={handleChange}
                      minLength={42}
                      maxLength={42}
                      autoComplete="off"
                      pattern="^[a-zA-Z0-9]*$"
                      required
                    />
                  </div>
                  <div className=" my-5 flex bot-left1 rounded-lg">
                    <div
                      className=" btn-bg p-2  flex justify-center items-center cursor-pointer  border-y border-l"
                      onClick={decrement}
                    >
                      <i className="fa-solid fa-minus text-center"></i>
                    </div>

                    <input
                      className={inpClass}
                      placeholder="Withdraw Amount"
                      type="number"
                      name="withdrawAmount"
                      onChange={handleChange}
                      min="1"
                      max={withdrawReward / 10}
                      // step="0.1"
                      value={value}
                      required
                    />

                    <div
                      className=" btn-bg p-2 flex justify-center items-center cursor-pointer  border-y border-r"
                      onClick={increment}
                    >
                      <i className="fa-solid fa-plus text-center"></i>
                    </div>
                  </div>

                  <button
                    className="!flex !justify-center mx-auto"
                    type="submit"
                  >
                    <Button btn={"Withdraw"} />
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
