import React, { useEffect, useState } from "react";
import useEncryption from "../EncryptData/EncryptData";
import instance from "../BaseUrl/BaseUrl";
import { useNavigate } from "react-router-dom";

const HocNodes = (OriginalComponent) => {
  const NewComponent = () => {
    const navigate = useNavigate();
    const { encryptData, decryptData } = useEncryption();
    const getdata = decryptData(localStorage.getItem("details"));

    const [totlenode, settotlenode] = useState();

    const [nodeDetails, setNodeDetails] = useState({
      smartnode: "",
      powernode: "",
      masternode: "",
    });

    const totalNodes = async () => {
      try {
        const encrypt = encryptData(
          JSON.stringify({
            email: getdata?.data?.userData?.email,
          })
        );
        const result = await instance.post("/totalNodes", {
          data: encrypt,
        });

        const results = decryptData(result.data.data);
        if (results.status) {
          // toast.success(results.message);
          settotlenode(results?.data?.total);
          setNodeDetails({
            smartnode: results.data.smartNodes,
            powernode: results.data.powerNodes,
            masternode: results.data.masterNodes,
          });
        } else {
          // toast.error(results.message);
        }
      } catch (err) {}
    };

    useEffect(() => {
      if (getdata?.data?.token === undefined) {
        navigate("/signUp");
      } else {
        totalNodes();
      }
    }, [getdata?.data?.token]);

    return (
      <OriginalComponent
        totlenode={totlenode}
        nodeDetails={nodeDetails}
        settotlenode={settotlenode}
      />
    );
  };

  return NewComponent;
};

export default HocNodes;
