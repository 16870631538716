/* eslint-disable react-hooks/exhaustive-deps */
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import SignUp from "./components/SignUp/SignUp";
import Dashboard from "./components/Dashboard/Dashboard";
import MyNode from "./components/MyNode/MyNode";
import Investments from "./components/Investments/Investments";
import FAQ from "./components/FAQ/FAQ";
import Referral from "./components/Referral/Referral";
import Logo from "./components/Logo/Logo";
import "./components/Login/SignUp.css";
import OTP from "./components/OTP/OTP";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword.jsx";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Profile from "./components/Profile/Profile";
import Protected from "./components/ProtectedRouter/Protected";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Congratulations from "./components/Congratulations/Congratulations";
import Withdraw from "./components/Withdraw/Withdraw";
import Staking from "./components/staking/Staking";
import History from "./components/History/History";
import Tree from "./components/Tree/Tree";

function App() {
  // localStorage.clear();
  return (
    <>
      <Toaster position="top-right" reverseOrder={false}>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {t.type !== "loading" && (
                  <button onClick={() => toast.dismiss(t.id)}>{icon}</button>
                )}
                {message}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <div className="flex  h-full">
        <Navbar />

        <div className="lg:ml-[54px] w-full h-screen h min-h-screen bg">
          <Logo />
          <Routes>
            {/* <Route element={<Protected />}>
              <Route path="/login" element={<Login />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/forgetpassword" element={<ForgetPassword />} />
              <Route path="/resetpassword" element={<ResetPassword />} />
            </Route>
            <Route path="/" element={<Dashboard />} />
            <Route path="/portfolio" element={<MyNode />} /> */}
            <Route path="/" element={<Staking />} />
            <Route path="/history" element={<History />} />
            <Route path="/tree" element={<Tree />} />
            {/* <Route path="/investments" element={<Investments />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/otp" element={<OTP />} />
            <Route path="/logo" element={<Logo />} />
            <Route path="/congratulation" element={<Congratulations />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<ErrorPage />} /> */}
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
